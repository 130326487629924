import axios from "axios";

export const cashDAO = {
    getCash: async (cid, userId, date) => {
        try {
            console.log(date);
            const response = await axios.post(
                `api/General/getCaja`,
                {
                    "id": 0,
                    "cia": cid,
                    "usuarioId": userId,
                    "fecha": date,
                    "monto": 0,
                    "cerrado": true
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error al obtener caja:", error);
            throw new Error("No se pudo obtener caja. Por favor, intenta de nuevo.");
        }
    },
    insertCash: async (cid, userId, date, amount) => {
        try {
            const response = await axios.post(
                `api/General/insertCaja`,
                {
                    "id": 0,
                    "cia": cid,
                    "usuarioId": userId,
                    "fecha": date,
                    "monto": amount,
                    "cerrado": false
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error al insertar caja:", error);
            throw new Error("No se pudo insertar caja. Por favor, intenta de nuevo.");
        }
    },
    closeCash: async (cid, cashId, userId) => {
        try {
            const response = await axios.get(
                `api/General/cerrarCaja?cia=${cid}&idCaja=${cashId}&UsuarioId=${userId}`, 
                { crossdomain: true }
              );

            return response.data;
        } catch (error) {
            console.error("Error al cerrar caja:", error);
            console.log(cid);
            console.log(cashId);
            console.log(userId);
            throw new Error("No se pudo cerrar caja. Por favor, intenta de nuevo.");
        }
    },
    insertCashMov: async (cid, userId, amount, cashId, type, description) => {
        try {
            const response = await axios.post(
                `api/General/insertCajaMov`,
                {
                    "id": 0,
                    "cia": cid,
                    "usuarioId": userId,
                    "CajaId": cashId,
                    "Tipo": type,
                    "monto": amount,
                    "descripcion": description
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error al insertar caja:", error);
            throw new Error("No se pudo insertar caja. Por favor, intenta de nuevo.");
        }
    },

}