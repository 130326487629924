import { cashDAO } from "../data/cashDAO";

export const CashService = {
    getOpenedCash: async (cid, userId) => {
        try {
            // Obtener la fecha y hora en la zona horaria de Costa Rica
            const date = new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" });

            console.log(date);
            const response = await cashDAO.getCash(cid, userId, date)

            return response.cerrado == false ? response : null

        } catch (error) {
            console.error("Error al consultar caja", error)
            throw new Error("No se pudo consultar caja. Por favor, intenta de nuevo.")
        }
    },
    openCash: async (cid, userId, amount) => {
        try {
            // Obtener la fecha y hora en la zona horaria de Costa Rica
            const date = new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" });

            const response = await cashDAO.insertCash(cid, userId, date, amount)

            return response

        } catch (error) {
            console.error("Error al abrir caja", error)
            throw new Error("No se pudo abrir caja. Por favor, intenta de nuevo.")
        }
    },
    closeCash: async (cid, cashId, userId) => {
        try {
            const response = await cashDAO.closeCash(cid, cashId, userId)

            return response

        } catch (error) {
            console.error("Error al abrir caja", error)
            throw new Error("No se pudo cerrar caja. Por favor, intenta de nuevo.")
        }
    },
    movementCash: async (cid, userId, amount, cashId, type, description) => {
        try {
            const response = await cashDAO.insertCashMov(cid, userId, amount, cashId, type, description)

            return response

        } catch (error) {
            console.error("Error al agregar movimiento de caja", error)
            throw new Error("No se pudo crear el movimiento de caja. Por favor, intenta de nuevo.")
        }
    },

}