<template>
    <v-container class="mt-5">
      <v-card>
        <v-card-title>Apertura de Caja</v-card-title>
        <v-card-text>
          <v-form ref="openForm" v-model="isValid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Monto inicial (efectivo)"
                  v-model="initialAmount"
                  :rules="[rules.required, rules.positive]"
                  type="number"
                  prefix="₡"
                  outlined
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Usuario"
                  :value="user"
                  outlined
                  disabled
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!isValid" color="primary" @click="start">
            Abrir Caja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </template>
  
  <script>


  export default {
    props: {
    user: {
      type: String,
      required: true,
    },
  },
    data() {
      return {
        isValid: false,
        initialAmount: null,
        selectedUser: null,
        rules: {
          required: (value) => !!value || "Este campo es requerido.",
          positive: (value) => value > 0 || "Debe ser un valor positivo.",
        },
      };
    },
    methods: {
      start() {
        if (this.$refs.openForm.validate()) {
            this.$emit("open-cash",[this.initialAmount]);
        }
      },
    }
  };
  </script>
  
  <style scoped>
  .v-card {
    max-width: 600px;
    margin: auto;
  }
  </style>
  